<template>
  <section>
      <div class="error-container" v-if="showError">{{ errorMessage }}</div>
      <div v-else class="main-data-container">
        <div class="entity-navbar">
          <div class="left d-flex">
            <h3 v-if="currentEntity && currentEntity.name">
              {{ currentEntity.name }}
            </h3>
          </div>
        </div>
        <!-- <div class="profile-view-container" v-if="activeLayout == 'PROFILE'">
          <el-row :gutter="15">
            <el-col :xl="5" :lg="4" :md="5">
              <div class="filter-card">
                <img
                  v-if="getFieldValue('profile')"
                  :src="getFieldValue('profile')"
                  style="border-radius: 50%"
                />
                <div v-else>
                  <i class="el-icon-picture-outline"></i> 
                  <img
                    v-if="currentEntity.entity_type == 'INDIVIDUAL'"
                    src="@/assets/img/defaultAvatar/Individual.svg"
                    alt="icon"
                    style="border-radius: 50%"
                  />
                  <img
                    v-else
                    src="@/assets/img/defaultAvatar/Business.svg"
                    alt="icon"
                    height="20"
                    width="20"
                  />
                </div>
                <div class="card-body">
                  <p class="card-title" v-if="getFieldValue('title')">
                    {{ getFieldValue("title") | truncate(24, "...") }}
                  </p>
                  <p
                    class="card-descripion"
                    v-if="getFieldValue('description')"
                  >
                    {{ getFieldValue("description") | truncate(140, "...") }}
                  </p>
                </div>
                <el-scrollbar wrap-style="max-height: 300px">
                  <div class="card-fields">
                    <div class="card-fields-inner">
                      <div
                        v-for="(field, i) in showFields.fields"
                        :key="field.key + '_show_fields'"
                        class="mt-1"
                      >
                        <div v-if="i < 5">
                          <span
                            class="field-label"
                            v-if="field && field.template_id && field.key"
                            >{{ field.label }}</span
                          >

                          <div v-if="field && field.input_type == 'DATE'">
                            <p class="field-value">
                              {{
                                getFieldValue("fields", field)
                                  | globalDateFormat
                              }}
                            </p>
                          </div>
                          <div
                            v-else-if="field && field.input_type == 'DATE_TIME'"
                          >
                            <p class="field-value">
                              {{
                                getFieldValue("fields", field)
                                  | moment("MM-DD-YYYY hh:mm:ss A")
                              }}
                            </p>
                          </div>

                          <div
                            v-else-if="
                              field && field.input_type == 'DATE_TIME_RANGE'
                            "
                          >
                            <p class="field-value">
                              {{
                                getFieldValue("fields", field) | toDateTimeRange
                              }}
                             
                            </p>
                          </div>
                          <div
                            v-else-if="
                              field && field.input_type == 'TIME_RANGE'
                            "
                          >
                            <p class="field-value">
                              {{ getFieldValue("fields", field) | toTimeRange }}
                            </p>
                          </div>
                          <div
                            v-else-if="field && field.input_type == 'WEEKDAYS'"
                          >
                            <p class="field-value">
                              {{ getFieldValue("fields", field) | toWeekDays }}
                            </p>
                          </div>
                          <div
                            v-else-if="
                              field && field.input_type == 'DATE_RANGE'
                            "
                          >
                            <p class="field-value">
                             
                              {{ getFieldValue("fields", field) | toDateRange }}
                            
                            </p>
                          </div>
                          <div v-else>
                            <p
                              class="field-value"
                              v-if="getFieldValue('fields', field)"
                            >
                              {{
                                getFieldValue("fields", field)
                                  | truncate(30, "...")
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
              </div>
            </el-col>
            <el-col :xl="18" :lg="20" :md="19" :sm="24" :xs="24">
              <div class="data-card-container">
                <el-tabs
                  type="card"
                  @tab-click="changeTab"
                  v-model="currrentTab"
                  class="data-card-tabs"
                >
                  <el-tab-pane
                    label="Details"
                    name="entities_data"
                  ></el-tab-pane>
                  <template>
                    <el-tab-pane
                      v-for="(reationship, index) of relationshipsData"
                      :key="'reationships' + index"
                      :label="reationship.relationship_title"
                      :name="reationship.relationship_id"
                    ></el-tab-pane>
                    <template v-if="showNestedRelaships">
                      <el-tab-pane
                        v-for="(reationship, index) of nestedRelationshipsData"
                        :key="
                          'nestedRelationshipsData' + index + reationship._id
                        "
                        :label="reationship.relationship_title"
                        :name="reationship._id"
                      ></el-tab-pane>
                    </template>
                  </template>
                  <el-tab-pane label="Files" name="files"></el-tab-pane>
                  <el-tab-pane
                    label="eSignatures"
                    name="esignature"
                  ></el-tab-pane>
                </el-tabs>
                <div style="width: 100% !important">
                  <el-row v-if="showTemplate && currrentTab == 'entities_data'">
                    <entity-template-view
                      v-if="currrentTab == 'entities_data'"
                      :currentEntity="currentEntity"
                      :entityData="entityData"
                      :isEdit="isEdit"
                      :layout="activeLayout"
                      :isApplicationUserSide="isApplicationUserSide"
                      :entityDataExists="entityDataExists"
                      v-on:refresh-page="refreshThePage"
                    ></entity-template-view>
                  </el-row>
                  <el-row v-else-if="currrentTab == 'esignature'">
                    <entity-documents
                      :entityId="entity_id"
                      :entityDataId="entityDataId"
                      :isDocument="true"
                    ></entity-documents>
                  </el-row>
                  <el-row v-else-if="currrentTab == 'files'">
                    <entity-data-documents
                      v-if="!refresh"
                      :entityDataId="entityDataId"
                      :entityId="entity_id"
                      :folder="folder"
                      @moveToFolder="moveToFolder"
                    ></entity-data-documents>
                  </el-row>
                  <el-row v-else-if="isCurrentSlugIsRelationship && showData">
                    <EntityReationshipDataTable
                      :relationshipDetails="getCurrentRelationshipDetails"
                      :nextRealationshipDetails="hasNextRelationshipDetails"
                      :hideLabel="true"
                    />
                  </el-row>

                  <el-row
                    v-else-if="isCurrentSlugIsNestedRelationship && showData"
                  >
                    <EntityNestedReationshipDataTable
                      :relationshipDetails="getCurrentNestedRelationshipDetails"
                      :nextRealationshipDetails="null"
                      :hideLabel="true"
                    />
                  </el-row>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="profile-view-container" v-else-if="activeLayout == 'COVER'">
          <el-row>
            <div class="profile-upper">
              <div id="profile-banner-image">
                <img
                  v-if="getFieldValue('cover_profile')"
                  :src="getFieldValue('cover_profile')"
                  style="border-radius: 50%"
                />
                <div class="empty-profile-banner-image" v-else>
                  <i class="el-icon-picture-outline"></i>
                </div>
              </div>
              <div id="profile-d">
                <div id="profile-pic">
                  <img
                    v-if="getFieldValue('profile')"
                    :src="getFieldValue('profile')"
                    style="border-radius: 50%"
                  />
                  <div v-else>
                   
                    <img
                      v-if="currentEntity.entity_type == 'INDIVIDUAL'"
                      src="@/assets/img/defaultAvatar/Individual.svg"
                      alt="icon"
                      style="border-radius: 50%"
                    />
                    <img
                      v-else
                      src="@/assets/img/defaultAvatar/Business.svg"
                      alt="icon"
                      height="20"
                      width="20"
                    />
                  </div>
                </div>
              </div>
              <div id="black-grd"></div>
            </div>
          </el-row>
          <el-row :gutter="15">
            <el-col :xl="5" :lg="5" :md="24" :sm="24" :xs="24">
              <div class="filter-card">
                <div class="card-body" style="margin-top: 75px !important">
                  <p class="card-title" v-if="getFieldValue('title')">
                    {{ getFieldValue("title") | truncate(24, "...") }}
                  </p>
                  <p
                    class="card-descripion"
                    v-if="getFieldValue('description')"
                  >
                    {{ getFieldValue("description") | truncate(140, "...") }}
                  </p>
                </div>
                <el-scrollbar wrap-style="max-height: 300px">
                  <div class="card-fields">
                    <div class="card-fields-inner">
                      <div
                        v-for="(field, i) in showFields.fields"
                        :key="field.key + '_show_fields_1'"
                        class="mt-1"
                      >
                       
                        <span
                          class="field-label"
                          v-if="field && field.template_id && field.key"
                          >{{ field.label }}</span
                        >
                        <p
                          class="field-value"
                          v-if="getFieldValue('fields', field)"
                        >
                          {{
                            getFieldValue("fields", field) | truncate(30, "...")
                          }}
                        </p>
                      </div> 
                        <div v-if="i < 5">
                          <span
                            class="field-label"
                            v-if="field && field.template_id && field.key"
                            >{{ field.label }}</span
                          >
                          <div v-if="field && field.input_type == 'DATE'">
                            <p class="field-value">
                              {{
                                getFieldValue("fields", field)
                                  | globalDateFormat
                              }}
                            </p>
                          </div>
                          <div
                            v-else-if="field && field.input_type == 'DATE_TIME'"
                          >
                            <p class="field-value">
                              {{
                                getFieldValue("fields", field)
                                  | moment("MM-DD-YYYY hh:mm:ss A")
                              }}
                            </p>
                          </div>
                          <div
                            v-else-if="
                              field && field.input_type == 'DATE_TIME_RANGE'
                            "
                          >
                            <p class="field-value">
                              {{
                                getFieldValue("fields", field) | toDateTimeRange
                              }}
                            
                            </p>
                          </div>
                          <div
                            v-else-if="
                              field && field.input_type == 'TIME_RANGE'
                            "
                          >
                            <p class="field-value">
                              {{ getFieldValue("fields", field) | toTimeRange }}
                            </p>
                          </div>
                          <div
                            v-else-if="field && field.input_type == 'WEEKDAYS'"
                          >
                            <p class="field-value">
                              {{ getFieldValue("fields", field) | toWeekDays }}
                            </p>
                          </div>
                          <div
                            v-else-if="
                              field && field.input_type == 'DATE_RANGE'
                            "
                          >
                            <p class="field-value">
                             
                              {{ getFieldValue("fields", field) | toDateRange }}
                           
                            </p>
                          </div>
                          <div v-else>
                            <p
                              class="field-value"
                              v-if="getFieldValue('fields', field)"
                            >
                              {{
                                getFieldValue("fields", field)
                                  | truncate(30, "...")
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
              </div>
            </el-col>
            <el-col :xl="19" :lg="19" :md="24" :sm="24" :xs="24">
              <div class="data-card-container">
                <el-tabs
                  type="card"
                  @tab-click="changeTab"
                  v-model="currrentTab"
                  class="data-card-tabs"
                >
                  <el-tab-pane
                    label="Details"
                    name="entities_data"
                  ></el-tab-pane>
                  <template>
                    <el-tab-pane
                      v-for="(reationship, index) of relationshipsData"
                      :key="'reationships' + index"
                      :label="reationship.relationship_title"
                      :name="reationship.relationship_id"
                    ></el-tab-pane>
                    <template v-if="showNestedRelaships">
                      <el-tab-pane
                        v-for="(reationship, index) of nestedRelationshipsData"
                        :key="'nestedRelationshipsData' + index"
                        :label="reationship.relationship_title"
                        :name="reationship._id"
                      ></el-tab-pane>
                    </template>
                  </template>
                  <el-tab-pane label="Files" name="files"></el-tab-pane>
                  <el-tab-pane
                    label="eSignatures"
                    name="esignature"
                  ></el-tab-pane>
                </el-tabs>
                <div style="width: 100% !important">
                  <el-row v-if="showTemplate && currrentTab == 'entities_data'">
                    <entity-template-view
                      v-if="currrentTab == 'entities_data'"
                      :currentEntity="currentEntity"
                      :entityData="entityData"
                      :isEdit="isEdit"
                      :layout="activeLayout"
                      :entityDataExists="entityDataExists"
                      v-on:refresh-page="refreshThePage"
                    ></entity-template-view>
                  </el-row>
                  <el-row v-else-if="currrentTab == 'esignature'">
                    <entity-documents
                      :entityId="entity_id"
                      :entityDataId="entityDataId"
                      :isDocument="true"
                    ></entity-documents>
                  </el-row>
                  <el-row v-else-if="currrentTab == 'files'">
                    <entity-data-documents
                      v-if="!refresh"
                      :entityDataId="entityDataId"
                      :entityId="entity_id"
                      :folder="folder"
                      @moveToFolder="moveToFolder"
                    ></entity-data-documents>
                  </el-row>
                  <el-row v-else-if="isCurrentSlugIsRelationship && showData">
                    <EntityReationshipDataTable
                      :relationshipDetails="getCurrentRelationshipDetails"
                      :nextRealationshipDetails="hasNextRelationshipDetails"
                      :hideLabel="true"
                    />
                  </el-row>

                  <el-row
                    v-else-if="isCurrentSlugIsNestedRelationship && showData"
                  >
                    <EntityNestedReationshipDataTable
                      :relationshipDetails="getCurrentNestedRelationshipDetails"
                      :nextRealationshipDetails="null"
                      :hideLabel="true"
                    />
                  </el-row>
                </div>
              </div>
            </el-col>
          </el-row>
        </div> -->
        <div class="default-view-container" >
          <!-- <div class="default-profile" v-if="checkIsOneFieldselected">
            <div class="left-card" v-if="showFields['profile']">
              <div class="profile-holder">
                <img
                  v-if="getFieldValue('profile')"
                  :src="getFieldValue('profile')"
                />
                <div v-else>
                  <img
                    v-if="currentEntity.entity_type == 'INDIVIDUAL'"
                    src="@/assets/img/defaultAvatar/Individual.svg"
                    alt="icon"
                    style="border-radius: 50%"
                  />
                  <img
                    v-else
                    src="@/assets/img/defaultAvatar/Business.svg"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
            <div class="right-card">
              <div class="profile-details">
                <p class="profile-heading" v-if="getFieldValue('title')"></p>
                <p class="card-descripion" v-if="getFieldValue('description')">
                  {{ getFieldValue("description") | truncate(300, "...") }}
                </p>
                <div class="default-card-fields">
                  <div
                    v-for="(field, i) in showFields.fields"
                    :key="field.key + '_show_fields_7' + i"
                    class="mt-1"
                  >
                    
                    <div v-if="i < 6">
                      <span
                        class="field-label"
                        v-if="field && field.template_id && field.key"
                        >{{ field.label }}</span
                      >

                      <div v-if="field && field.input_type == 'DATE'">
                        <p class="field-value">
                          {{
                            getFieldValue("fields", field) | globalDateFormat
                          }}
                        </p>
                      </div>
                      <div v-else-if="field && field.input_type == 'DATE_TIME'">
                        <p class="field-value">
                          {{
                            getFieldValue("fields", field)
                              | moment("MM-DD-YYYY hh:mm:ss A")
                          }}
                        </p>
                      </div>

                      <div
                        v-else-if="
                          field && field.input_type == 'DATE_TIME_RANGE'
                        "
                      >
                        <p class="field-value">
                          {{ getFieldValue("fields", field) | toDateTimeRange }}
                         
                        </p>
                      </div>
                      <div
                        v-else-if="field && field.input_type == 'TIME_RANGE'"
                      >
                        <p class="field-value">
                          {{ getFieldValue("fields", field) | toTimeRange }}
                        </p>
                      </div>
                      <div v-else-if="field && field.input_type == 'WEEKDAYS'">
                        <p class="field-value">
                          {{ getFieldValue("fields", field) | toWeekDays }}
                        </p>
                      </div>
                      <div
                        v-else-if="field && field.input_type == 'DATE_RANGE'"
                      >
                        <p class="field-value">
                          
                          {{ getFieldValue("fields", field) | toDateRange }}
                       
                        </p>
                      </div>
                      <div v-else>
                        <p
                          class="field-value"
                          v-if="getFieldValue('fields', field)"
                        >
                          {{
                            getFieldValue("fields", field) | truncate(30, "...")
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div style="width: 100% !important">
            <el-row v-if="showTemplate && currrentTab == 'entities_data'">
              <entity-template-view
                v-if="currrentTab == 'entities_data'"
                :currentEntity="currentEntity"
                :entityData="entityData"
                :isEdit="isEdit"
                :layout="'STANDARD'"
                :entityDataExists="entityDataExists"
                v-on:refresh-page="refreshThePage"
              ></entity-template-view>
            </el-row>
          </div>
        </div>
      </div>

  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { makeSlug } from "../../helpers/appHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";

import { bus } from "../../main";
export default {
  data() {
    return {
      activeName: "1",
      activeLayout: "",
      loading: false,
      currentEntity: {},
      loadingText: "Gathering data...",
      entity_id: "",
      entityDataId: "",
      relationshipsData: [],
      nestedRelationshipsData: [],
      showError: false,
      errorMessage: "Someting went wrong...!",
      showFields: {
        profile: "",
        cover_profile: "",
        title: "",
        description: "",
        fields: [],
      },
      currentEntityData: null,
      currrentTab: "entities_data",
      isEdit: false,
      showTemplate: false,
      contentFields: [
        "IMAGE",
        "HEADING",
        "PARAGRAPH",
        "VIDEO",
        "SINGLE_LINE_CONTENT",
        "HORIZONTAL_LINE",
        "DIVISION",
        "AGGREGATE_FUNCTION",
        "HTML_CONTENT",
        "ACTION_BUTTON",
        "HTML_CONTENT",
        "MASKED",
        "HYPER_LINK",
        "DATA_TABLE",
      ],
      entityViewModal: false,
      search_field: "",
      activeTemplate: "1",
      addPrimaryDataDialog: false,
      form: {},
      entityAllFields: [],
      primaryDataAddLoading: false,
      primaryDataAddLoadingText: "",
      defaultTabs: ["entities_data", "files", "document_data"],
      currentRelationshipDetails: {},
      currentEntityNestedRelationships: {},
      templateKey: "",
      currentRelationshipIndex: -1,
      hasNextRelationshipDetails: null,
      isApplicationUserSide: false,
      showData: true,
      showNestedRelaships: false,
      folder: "",
      refresh: false,
      entityDataExists: false,
      dialogVisible: true,
    };
  },
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityNestedRelationships",
      "getEntityRelationships",
      "getCurrentEntityRelationships",
      "getCurrentEntityNestedRelationships",
    ]),
    ...mapGetters("filters", ["getSingleFilterData", "getAllEntityFilters"]),
    ...mapGetters("entities", [
      "getEntityDataById",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityDocumentsNotIncludingTemplatesData",
      "getCompanyEntityDetails",
      "getEntityPrimaryData",
      "getEntityShowFields",
      "getEntityDataExists",
    ]),
    ...mapGetters("templatesData", [
      "getNewEntityData",
      "getPrimaryEntityDataStatus",
      "getTemplatesData",
      "getTemplatesPrimaryData",
      "getformtemplateinvoiceinfo",
      "getDuplicateDataCheck",
    ]),
    ...mapGetters("menuManagementV2", ["getEntityMenu"]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveContactType"]),
    ...mapGetters("applicationUsersPermission", ["getEntityPermission"]),
    entityData() {
      return this.currentEntityData;
    },
    getGalleryImages() {
      return [];
    },
    getSearchData() {
      let result = [];
      if (this.search_field) {
        this.currentEntity.templates.forEach((td) => {
          let template = JSON.parse(JSON.stringify(td));
          if (template?.templateInfo?.sections && !template.is_repeatable) {
            let filterFields = template.templateInfo.sections[0].fields.filter(
              (field) =>
                field?.label &&
                field.label
                  .toLowerCase()
                  .includes(this.search_field.toLocaleLowerCase())
            );
            if (filterFields && filterFields.length > 0) {
              template.templateInfo.sections[0].fields = [];
              template.templateInfo.sections[0].fields = [...filterFields];
              result.push(template);
            }
          }
        });
      }
      return result;
    },
    getPrimaryFields() {
      if (this.getEntityDataById && this.getEntityDataById.primaryFields) {
        let fields = this.entityAllFields.map((e) => {
          if (e?.key && e.key.includes("#")) {
            e.key = e.key.split("#")[1];
          }
          return e;
        });
        return fields.filter((e) => {
          return (
            this.getEntityDataById.primaryFields
              .flatMap((e) => e.key)
              .indexOf(e.key) !== -1
          );
        });
      }
      return [];
    },
    getRequired() {
      return this.getPrimaryFields.filter(
        (e) => this.form[e.key] || typeof this.form[e.key] === "number"
      ).length;
    },
    getTotalCount() {
      return this.getPrimaryFields.filter((e) => e.key).length;
    },
    isCurrentSlugIsRelationship() {
      let relationship = this.$route.query.relationship_id;
      if (relationship) {
        return (
          this.getCurrentRelationshipDetails &&
          this.getCurrentRelationshipDetails.relationship_id == relationship
        );
      }
      return false;
    },

    isCurrentSlugIsNestedRelationship() {
      let relationship = this.$route.query.relationship_id;
      return (
        relationship &&
        this.getCurrentNestedRelationshipDetails &&
        this.getCurrentNestedRelationshipDetails._id == relationship
      );
    },
    getCurrentRelationshipDetails() {
      return this.currentRelationshipDetails;
    },
    getCurrentNestedRelationshipDetails() {
      return this.currentEntityNestedRelationships;
    },
    checkIsOneFieldselected() {
      let selectedFields = ["profile", "title", "description", "fields"];
      if (this.showFields && Object.keys(this.showFields).length > 0) {
        let field = Object.keys(this.showFields).find(
          (e) =>
            selectedFields.indexOf(e) != -1 &&
            this.showFields[e] &&
            this.showFields[e].length
        );
        if (field) {
          return true;
        }
      }
      return false;
    },
  },
  mixins: [NavigationHelper, entityRelationshipMixin],
  mounted() {
    // /:entityDataId/:entityId/:templateId
    this.entity_id = this.$route.params.entityId;
    this.entityDataId = this.$route.params.entityDataId;
    if (this.$route.query.layout) {
      this.activeLayout = this.$route.query.layout;
    }
    if (this.$route.query.activeTab) {
      this.currrentTab = this.$route.query.activeTab;
    }
    if (
      this.$route.name == "EntityDetailedEdit" ||
      this.$route.name == "ApEntityExecute"
    ) {
      this.isEdit = true;
    }
    if (
      this.$route.name == "ApEntityExecute" ||
      this.$route.name == "ApPreviewEntityData"
    ) {
      this.isApplicationUserSide = true;
    }
    if (this.entity_id) {
      this.getNecessaryInfo();
    } else {
      this.showError = true;
      this.errorMessage = "Invalid url. Please check url..!";
    }
    bus.$on("fetch-entity-data", () => {
      this.entityDataId = this.$route.params.entityDataId;
      this.fetchNewEntityData();
    });
    if (this.$route?.query?.folder) {
      this.folder = this.$route.query.folder;
    }
  },
  components: {
    EntityTemplateView: () => import("./EntityTemplateView.vue"),
    // PrimaryDataForm: () => import("./PrimaryDataCollectionForm"),
    // EntityReationshipDataTable: () =>
    //   import("./EntityReationshipDataTable.vue"),
    // EntityDocuments: () =>
    //   import("@/components/entity/userProfileEntityDocumentTemplates"),
    // EntityNestedReationshipDataTable: () =>
    //   import("./EntityNestedReationshipDataTable.vue"),
    // EntityDataDocuments: () => import("./EntityDataDocuments.vue"),
  },
  methods: {
    emitSaveTemplate1() {
      this.$bus.$emit("save-template", false);
    },
    emitSaveTemplate2() {
      this.$bus.$emit("save-template", true);
    },
    emitGoToEditMode() {
      this.$bus.$emit("go-to-edit-mode", true);
    },
    emitCloneEntityData() {
      this.$bus.$emit("clone-entity-data", true);
    },
    async refreshThePage() {
      this.entity_id = this.$route.params.entityId;
      this.entityDataId = this.$route.params.entityDataId;
      this.showTemplate = false;
      this.loading = true;
      if (!this.entityDataId) {
        this.entityDataExists = false;
        this.currentEntityData = null;
      }
      setTimeout(() => {
        this.showTemplate = true;
        this.loading = false;
      }, 100);
    },
    async moveToFolder(folderId) {
      this.refresh = true;
      this.folder = folderId != "root" ? folderId : "";
      let query = await this.getNavigationQuery(this.$route.query);
      if (this.folder) {
        query["folder"] = this.folder;
      } else {
        if (query.folder) {
          delete query.folder;
        }
      }
      this.$router.push({
        name: this.$route.name,
        query: query,
      });
      setTimeout(() => {
        this.refresh = false;
      }, 500);
    },
    async addPrimaryDataToEntity() {
      try {
        this.loading = true;
        this.primaryFields = [...this.getPrimaryFields];
        if (this.form && this.getRequired === this.getTotalCount) {
          this.primaryFields = [...this.getPrimaryFields];

          this.primaryFields.forEach((e) => {
            if (
              e.input_type == "ENTITY" &&
              e.entity_id &&
              this.form[e.key] &&
              this.form[e.key + "/name"]
            ) {
              e["entity_data_key"] = e.key + "/name";
            }
          });
          //setting auto increment data
          let incrementFields = this.primaryFields.filter(
            (e) => e.input_type == "AUTO_INCREMENT_NUMBER"
          );

          if (incrementFields && incrementFields.length) {
            await incrementFields.map(async (field) => {
              this.form[`${field.key}_info`] = {
                number: field.auto_increment_starting_number,
                prefix: field.auto_increment_prefix,
              };
            });
          }
          await this.checkForDuplicateData();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Please fill all the fields in primary data",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async checkForDuplicateData() {
      this.primaryDataAddLoading = true;
      this.primaryDataAddLoadingText = "Checking duplicate data...";
      let templateData = [];
      this.primaryFields.forEach((pf) => {
        let findIndex = templateData.findIndex(
          (e) => e.template_id == pf.template_id
        );
        if (findIndex > -1) {
          let existedData = templateData[findIndex];
          existedData.form = {
            ...existedData.form,
            ...{ [pf.key]: this.form[pf.key] },
          };
          templateData[findIndex] = { ...existedData };
        } else {
          templateData.push({
            template_id: pf.template_id,
            form: { [pf.key]: this.form[pf.key] },
          });
        }
      });
      let params = {
        entity_id: this.$route.params.entityId,
        parent_entityDataId: null,
        data: templateData,
      };
      await this.$store.dispatch("templatesData/checkDuplicateData", params);
      if (this.getDuplicateDataCheck) {
        if (!this.getDuplicateDataCheck?.allowed) {
          this.$notify.warning({
            title: "Warning",
            message:
              "With this data already " +
              (this.getEntityDataById && this.getEntityDataById.name
                ? this.getEntityDataById.name + " is"
                : "data is") +
              " created.",
          });
          this.primaryDataAddLoading = false;
          this.primaryDataAddLoadingText = "";
          return;
        }
        this.primaryDataAddLoading = false;
        this.primaryDataAddLoadingText = "";
        this.createEntityData();
      } else {
        this.primaryDataAddLoading = false;
        this.primaryDataAddLoadingText = "";
        this.$message({
          message: "Error while checking data",
          type: "error",
        });
      }
    },
    async createEntityData() {
      this.primaryDataAddLoading = true;
      this.primaryDataAddLoadingText = "Creating new entity data...";
      let data = {
        primaryFields: this.getEntityDataById.primaryFields,
        formData: this.form,
        templates: this.getEntityDataById.templates,
        entity_id: this.$route.params.entityId,
      };
      await this.$store.dispatch("templatesData/createEntityPrimaryData", data);
      if (
        this.getPrimaryEntityDataStatus.message ===
        "Primary fields data required"
      ) {
        this.primaryDataAddLoading = false;
        this.primaryDataAddLoadingText = "";
        this.$notify.error({
          title: "Error",
          message:
            "This form contains unique field.Cannot not create duplicate value",
        });
      } else {
        if (this.getPrimaryEntityDataStatus) {
          this.addPrimaryDataDialog = false;
          this.primaryDataAddLoading = false;
          this.primaryDataAddLoadingText = "";
          this.$notify.success({
            title: "Success",
            message: "Primary Data saved successfully",
          });
          if (this.getNewEntityData) {
            this.entityDataId = this.getNewEntityData._id;
            await this.checkAndSetRelationship();
            let query = await this.getNavigationQuery(this.$route.query);
            query["dataId"] = this.entityDataId;
            this.showTemplate = true;
            if (
              this.getCurrentEntityRelationships &&
              this.getCurrentEntityRelationships.length
            ) {
              await this.setRelationshipDetails();
            }
            this.$router.push({
              name: this.$route.name,
              query: query,
            });
            // window.location.reload();
            await this.getNecessaryInfo();
          }
        } else {
          this.primaryDataAddLoading = false;
          this.primaryDataAddLoadingText = "";
          this.$notify.error({
            title: "Error",
            message: "Error at saving data",
          });
        }
      }
    },
    async checkAndSetRelationship() {
      if (
        this.$route.query.menu_id &&
        this.getEntityMenu &&
        this.getEntityMenu.menu_list
      ) {
        let currentMenu;
        let id = this.$route.params.menuId || this.$route.query.menu_id;
        this.getEntityMenu.menu_list.forEach((e) => {
          if (e._id == id) {
            currentMenu = e;
          } else if (e.children) {
            (e.children || []).forEach((el) => {
              if (el._id == id) {
                currentMenu = el;
              }
            });
          }
        });
        if (currentMenu?.relational_entity == this.$route.params.entityId) {
          let relationshipsData = [...(this.getEntityRelationships || [])];

          relationshipsData = relationshipsData.map((e) => {
            if (e.parent_entity_id == this.$route.params.entityId) {
              e.owner_type = "PARENT";
              e.relationship_title = e.child_relation_title;
              e.relationship_slug = makeSlug(e.relationship_title);
              e.relationship_id = e.child_entity_id;
            } else {
              e.owner_type = "CHILD";
              e.relationship_title = e.parent_relation_title;
              e.relationship_slug = makeSlug(e.relationship_title);
              e.relationship_id = e.parent_entity_id;
            }
            e.parent_entity_data_id = this.entityDataId;
            return e;
          });

          relationshipsData = relationshipsData.filter((e) => {
            if (e.owner_type == "CHILD" && !e.is_bidirectional) {
              return false;
            } else {
              return true;
            }
          });
          let currentActiveWorkspace =
            this.getAuthenticatedUser.contact_types.find((e) => {
              let id =
                e.contact_type && e.contact_type._id
                  ? e.contact_type._id
                  : e.contact_type;
              if (id && id == this.getActiveContactType?.contact_type?._id) {
                return true;
              }
            });
          if (
            currentActiveWorkspace &&
            currentActiveWorkspace.contact_type &&
            currentActiveWorkspace.account_data_id
          ) {
            let currentRelationship = relationshipsData.find((e) => {
              let id = currentActiveWorkspace.contact_type._id
                ? currentActiveWorkspace.contact_type._id
                : currentActiveWorkspace.contact_type;
              if (id) {
                if (
                  (id == e.child_entity_id &&
                    e.parent_entity_id == this.$route.params.entityId) ||
                  (this.$route.params.entityId == e.child_entity_id &&
                    e.parent_entity_id == id)
                ) {
                  return true;
                }
              }
            });
            if (currentRelationship) {
              if (currentRelationship.representation == "TAB") {
                let data;
                if (currentRelationship.owner_type == "PARENT") {
                  data = {
                    parent_entity_id: currentRelationship.parent_entity_id,
                    child_entity_id: currentRelationship.child_entity_id,
                    parent_entity_data_id:
                      currentRelationship.parent_entity_data_id,
                    child_entity_data_ids: [
                      currentActiveWorkspace.account_data_id,
                    ],
                  };
                } else {
                  if (currentRelationship.parent_relation_type == "MANY") {
                    data = {
                      parent_entity_id: currentRelationship.child_entity_id,
                      child_entity_id: currentRelationship.parent_entity_id,
                      parent_entity_data_id:
                        currentRelationship.parent_entity_data_id,
                      child_entity_data_ids: [
                        currentActiveWorkspace.account_data_id,
                      ],
                    };
                  } else {
                    data = {
                      parent_entity_id: currentRelationship.parent_entity_id,
                      child_entity_id: currentRelationship.child_entity_id,
                      parent_entity_data_id:
                        currentActiveWorkspace.account_data_id,
                      child_entity_data_ids: [
                        currentRelationship.parent_entity_data_id,
                      ],
                    };
                  }
                }
                await this.$store.dispatch(
                  "entityRelationships/assignEntityRelationshipData",
                  data
                );
              } else {
                let allFields = this.getAllFieldsFormEntity(
                  this.getEntityDataById
                );
                let toAssignRelationshipsData = this.prepareRelationshipData(
                  allFields,
                  relationshipsData,
                  this.entityDataId,
                  currentActiveWorkspace &&
                    currentActiveWorkspace.account_data_id
                    ? currentActiveWorkspace.account_data_id
                    : null
                );
                if (toAssignRelationshipsData.length) {
                  await this.$store.dispatch(
                    "entityRelationships/assignFieldsEntityRelationshipData",
                    toAssignRelationshipsData
                  );
                }
              }
            }
          }
        }
      }
    },
    getAllFieldsFormEntity(entity) {
      if (entity && entity.templates && entity.templates.length) {
        let result = [];
        entity.templates.forEach((e) => {
          if (e && e.templateInfo) {
            result = [...result, ...this.getTemplateFields(e.templateInfo)];
          }
        });
        return result;
      }
      return [];
    },
    async fetchNewEntityData() {
      this.loading = true;
      await this.$store.dispatch("entities/fetchEntityDataByIdWithOutAccessToken", {
        id: this.entityDataId,
      });
      if (this.getEntityPrimaryData?._id) {
        this.currentEntityData = this.getEntityPrimaryData;
      }
      this.loading = false;
    },
    selectField(data, template_id) {
      let field = { ...data, ...{ template_id: template_id } };
      let existed = this.checkfieldVisible(field, template_id);
      let key;
      if (field.key.includes("#")) {
        key = field.key.split("#")[1];
      } else {
        key = field.key;
      }
      if (existed && this.showFields?.fields) {
        let index = this.showFields.fields.findIndex((e) => {
          if (e?.key) {
            let tempKey = e.key;
            if (e.key.includes("#")) {
              tempKey = e.key.split("#")[1];
            }
            if (tempKey == key && e.template_id == template_id) {
              return true;
            }
          }
        });
        if (index > -1) {
          this.showFields.fields.splice(index, 1);
        } else {
          field = { ...field, ...{ key: template_id + "#" + key } };
          this.showFields.fields.push(field);
        }
      } else {
        field = { ...field, ...{ key: template_id + "#" + key } };
        this.showFields.fields.push(field);
      }
    },
    async checkForFilterUpdate() {
      this.entityViewModal = false;
      // if (this.currentFilter) {
      //   await this.$store.dispatch("filters/updateFilter", {
      //     id: this.currentFilter,
      //     params: {
      //       columns_settings: this.filterColumList,
      //     },
      //   });
      // } else {
      if (this.isApplicationUserSide) {
        let data = this.getEntityShowFields || {};
        if (data) {
          data[this.getEntityDataById._id] = { ...this.showFields };
        }
        await this.$store.commit("entities/setEntityShowFields", data, {
          root: true,
        });
      } else {
        await this.$store.dispatch("entities/updateEntityById", {
          settings: this.getEntityDataById.settings,
          name: this.getEntityDataById.name,
          description: this.getEntityDataById.description,
          entity_type: this.getEntityDataById.entity_type,
          templates: this.getEntityDataById.templates,
          id: this.getEntityDataById._id,
          primaryFields: this.getEntityDataById.primaryFields,
          views_configuration: this.showFields,
        });
      }
      // }
    },
    checkfieldVisible(data, template_id) {
      if (
        this.showFields &&
        this.showFields.fields &&
        this.showFields.fields.length &&
        data?.key
      ) {
        let key = data.key;
        if (data.key.includes("#")) {
          key = data.key.split("#")[1];
        }
        let found = this.showFields.fields.find((el) => {
          let savedkey = el.key;
          if (el.key && el.key.includes("#")) {
            savedkey = el.key.split("#")[1];
          }
          if (el?.template_id == template_id && savedkey == key) {
            return true;
          }
        });
        return found ? true : false;
      }
      return false;
    },
    getEntityFieldsByType(types) {
      let results = [];
      (this.currentEntity?.templates || []).forEach((temp) => {
        if (temp?.templateInfo?.sections) {
          if (types && types.length) {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields
                .filter((e) => types.indexOf(e.inputType) != -1)
                .map((el) => {
                  let key = el.key;
                  if (key && !key.includes("#")) {
                    key = temp.template_id + "#" + key;
                  }
                  el.key = key;
                  return el;
                }),
            ];
          } else {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields.map((el) => {
                let key = el.key;
                if (key && !key.includes("#")) {
                  key = temp.template_id + "#" + key;
                }
                el.key = key;
                return el;
              }),
            ];
          }
        }
      });
      if (
        types &&
        types.includes("IMAGE") &&
        this.currentEntity?.entity_type == "INDIVIDUAL"
      ) {
        results.push({
          label: "Default picture",
          key: "defaultpic",
        });
      }
      return results;
    },
    openViewConfigPage() {
      this.entityViewModal = true;
    },
    async backToEntityInfoList() {
      let tempQuery = {};
      if (this.$route.query?.fromCustom) {
        this.$router.go(-1);
      } else {
        if (this.$route?.query?.filter) {
          tempQuery["filter"] = this.$route.query.filter;
        }
        if (this.$route?.query?.viewType) {
          tempQuery["viewType"] = this.$route.query.viewType;
        }
        if (this.$route?.query?.routeType) {
          tempQuery["routeType"] = this.$route.query.routeType;
        }
        if (this.isApplicationUserSide) {
          this.$router.push({
            path:
              "/ap/entity/" + this.entity_id + "/" + this.$route.query.menu_id,
            query: tempQuery,
          });
        } else {
          this.$router.push({
            path: "/entity/" + this.entity_id,
            query: tempQuery,
          });
        }
      }
    },
    async switchEntityView(view) {
      if (view != this.activeLayout) {
        this.activeLayout = view;
        let query = await this.getNavigationQuery(this.$route.query);
        query["layout"] = view;
        if (this.$route?.query?.relationship_id) {
          query["relationship_id"] = this.$route.query.relationship_id;
        }
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
      }
    },
    async changeTab() {
      let query = await this.getNavigationQuery(this.$route.query);
      query["activeTab"] = this.currrentTab;
      if (this.defaultTabs.indexOf(this.currrentTab) == -1) {
        query["relationship_id"] = this.currrentTab;
      }
      this.$router.push({
        name: this.$route.name,
        query: query,
      });
      this.loading = true;
      this.showData = false;
      setTimeout(() => {
        this.loading = false;
        this.showData = true;
      }, 1000);
    },
    getFieldValue(field, fieldKey) {
      if (this.currentEntityData?.templates_data) {
        if (field == "fields" && fieldKey?.key) {
          let [tempId, key] = fieldKey.key.split("#");
          if (!tempId) {
            tempId = fieldKey.template_id;
          }
          let templateData = this.currentEntityData.templates_data.find(
            (e) => e.template_id == tempId
          );
          if (templateData?.template_data_id?.template_data?.[key]) {
            if (
              templateData?.template_data_id?.template_data?.[key + "/name"]
            ) {
              return templateData?.template_data_id?.template_data?.[
                key + "/name"
              ];
            }
            return templateData?.template_data_id?.template_data?.[key];
          }
          return "";
        } else {
          if (
            this.currentEntityData?.templates_data &&
            this.showFields[field] &&
            this.showFields[field].includes("#")
          ) {
            let [tempId, key] = this.showFields[field].split("#");
            let templateData = this.currentEntityData.templates_data.find(
              (e) => e.template_id == tempId
            );
            if (templateData?.template_data_id?.template_data?.[key]) {
              if (
                templateData?.template_data_id?.template_data?.[key + "/name"]
              ) {
                return templateData?.template_data_id?.template_data?.[
                  key + "/name"
                ];
              }
              return templateData?.template_data_id?.template_data?.[key];
            }
            return "";
          } else {
            if (this.currentEntity.entity_type == "INDIVIDUAL") {
              if (
                field == "profile" &&
                this.currentEntityData?.contact_id?.avatar &&
                this.showFields[field] == "defaultpic"
              ) {
                return this.currentEntityData.contact_id.avatar;
              }
            }
            return "";
          }
        }
      }
    },
    async fetchEntityRelationships() {
      this.loading = true;
      this.loadingText = "Fetching Entity relationships..";
      //Fetch application user permissions
      if (
        this.getActiveContactType?.contact_type?._id &&
        this.isApplicationUserSide
      ) {
        await Promise.all([
          this.$store.dispatch(
            "applicationUsersPermission/fetchPermissionsEntityById",
            { id: this.getActiveContactType.contact_type._id }
          ),
          this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
            entity_id: this.entity_id,
          }),
        ]);
      } else {
        await this.$store.dispatch(
          "entityRelationships/fetchEntityRelationships",
          { entity_id: this.entity_id }
        );
      }
      if (this.getEntityRelationships && this.getEntityRelationships.length) {
        this.setRelationshipDetails();
      }

      this.loading = false;
    },
    async fetchEntityNestedRelationships() {
      this.loading = true;
      this.loadingText = "Fetching  Nested Entity relationships...";
      await this.$store.dispatch(
        "entityRelationships/fetchEntityNestedRelationships",
        { entity_id: this.entity_id }
      );

      this.nestedRelationshipsData = [
        ...(this.getEntityNestedRelationships || []),
      ];
      // we have to add type owner of relationship
      this.nestedRelationshipsData = this.nestedRelationshipsData.map((e) => {
        e.owner_type = "PARENT";
        e.relationship_title =
          e.parent_relationship_details.relationship_title +
          "/" +
          e.child_relation_title;
        e.relationship_slug =
          e.parent_relationship_details.relationship_slug +
          "-" +
          makeSlug(e.relationship_title);
        e.relationship_id = e.child_entity_id;
        e.parent_entity_data_id = this.entityDataId;
        return e;
      });

      this.$store.dispatch(
        "entityRelationships/updateCurrentEntityNestedRelationships",
        this.nestedRelationshipsData
      );
      this.loading = false;
    },
    setRelationshipDetails() {
      this.relationshipsData = [...(this.getEntityRelationships || [])];

      // we have to add type owner of relationship
      this.relationshipsData = this.relationshipsData.map((e) => {
        if (e.parent_entity_id == this.$route.params.entityId) {
          e.owner_type = "PARENT";
          e.relationship_title = e.child_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.child_entity_id;
        } else {
          e.owner_type = "CHILD";
          e.relationship_title = e.parent_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.parent_entity_id;
        }
        e.parent_entity_data_id = this.entityDataId;
        return e;
      });

      this.relationshipsData = this.relationshipsData.filter((e) => {
        if (e.owner_type == "CHILD" && !e.is_bidirectional) {
          return false;
        } else {
          return true;
        }
      });

      this.$store.dispatch(
        "entityRelationships/updateCurrentEntityRelationships",
        this.relationshipsData
      );
      this.relationshipsData = this.relationshipsData.filter(
        (e) => e.owner_type == "CHILD" || e.representation == "TAB"
      );
      //have to check application user permisson entities
      if (this.isApplicationUserSide) {
        this.relationshipsData = this.relationshipsData.filter((e) => {
          if (
            this.getEntityPermission?.other_entities &&
            this.getEntityPermission.other_entities[e.relationship_id] &&
            this.getEntityPermission.other_entities[
              e.relationship_id
            ].permissions.indexOf("VIEW") != -1
          ) {
            return true;
          }
        });
      }
    },
    async getNecessaryInfo() {
      this.loading = true;
      this.showError = true;
      this.errorMessage = "";
      await Promise.all([
        this.$store.dispatch("entities/fetchEntityById", {
          entity_id: this.entity_id,
        }),
        // this.fetchEntityRelationships(),
        // this.fetchEntityNestedRelationships(),
      ]);
      if (this.getEntityDataById) {
        this.currentEntity = JSON.parse(JSON.stringify(this.getEntityDataById));
        if (this.currentEntity && this.currentEntity.templates) {
          this.generateEntityFields();
          if (
            this.isApplicationUserSide &&
            this.getEntityShowFields &&
            this.getEntityShowFields[this.entity_id]
          ) {
            this.showFields = { ...this.getEntityShowFields[this.entity_id] };
          } else if (this.currentEntity?.views_configuration) {
            this.showFields = {
              ...this.currentEntity.views_configuration,
            };
          } else {
            await this.generateShowFields(this.currentEntity);
          }
          if (
            typeof this.showFields.fields == "string" ||
            this.showFields.fields == "" ||
            !this.showFields.fields
          ) {
            this.showFields.fields = [];
          }
          if (this.showFields) {
            this.checkFieldsExists();
          }
        }
        this.entityAllFields = [];
        this.getEntityDataById.templates.forEach((e) => {
          if (e && e?.templateInfo?.sections && !e.is_repeatable) {
            this.entityAllFields = [
              ...this.entityAllFields,
              ...e.templateInfo.sections[0].fields.map((fl) => {
                if (fl?.key && fl.key.includes("#")) {
                  fl.key = fl.key.split("#")[1];
                }
                fl.template_id = e.template_id;
                return fl;
              }),
            ];
          }
        });
        if (this.$route.query?.filter) {
          await this.$store.dispatch(
            "filters/getFilterById",
            this.$route.query?.filter
          );
        }
        if (this.entityDataId) {
          this.loading = true;
          this.loadingText = "Gathering existing data...!";
          await this.$store.dispatch("entities/fetchEntityDataByIdWithOutAccessToken", {
            id: this.entityDataId,
          });
          if (this.currentEntity?.edit_filter) {
            await this.checkEditMode();
          }
          if (this.getEntityPrimaryData?._id) {
            this.currentEntityData = this.getEntityPrimaryData;
          }
          this.showTemplate = true;
          this.loading = false;
          this.showError = false;
        } else {
          // if (this.getEntityDataById?.entity_type !== "INDIVIDUAL") {
          //   this.addPrimaryDataDialog = true;
          // }
          this.showTemplate = true;
          this.loading = false;
          this.showError = false;
        }
      } else {
        this.showError = true;
        this.errorMessage = "Invalid access. Entity not found...!";
      }
      this.loading = false;
    },
    async checkEditMode() {
      await this.$store.dispatch("entities/checkEntityDataByFilters", {
        entityDataId: this.entityDataId,
        entityId: this.currentEntity._id,
        filterId: this.currentEntity.edit_filter,
      });
      if (this.getEntityDataExists) {
        this.entityDataExists = true;
      }
    },
    checkFieldsExists() {
      this.showFields.fields = (this.showFields.fields || []).filter(
        (e) =>
          this.eliminateEntityFields(e.key) &&
          this.contentFields.indexOf(e.inputType) == -1
      );
      if (
        this.showFields.title &&
        !this.eliminateEntityFields(this.showFields.title)
      ) {
        this.showFields.title = "";
      }
      if (
        this.showFields.description &&
        !this.eliminateEntityFields(this.showFields.description)
      ) {
        this.showFields.description = "";
      }
    },
    eliminateEntityFields(field) {
      return this.selectedEntityFields.find((e) => e == field) ? true : false;
    },
    generateEntityFields() {
      this.selectedEntityFields = [];
      if (this.currentEntity && this.currentEntity.templates) {
        this.currentEntity.templates.forEach((e) => {
          if (e && e.templateInfo && !e.is_repeatable) {
            this.selectedEntityFields = [
              ...this.selectedEntityFields,
              ...this.getTemplateFields(e.templateInfo),
            ];
          }
        });
      }
      this.selectedEntityFields = this.selectedEntityFields.flatMap(
        (e) => e.key
      );
    },
    generateShowFields(entity) {
      if (entity?.templates) {
        if (entity?.entity_type == "INDIVIDUAL") {
          let standardTemp = entity?.templates.find(
            (e) => e && e?.templateInfo?.type == "STANDARD"
          );
          this.showFields.profile = "defaultpic";
          if (standardTemp?.template_id) {
            this.showFields.title = standardTemp.template_id + "#" + "name";
            this.showFields.description =
              standardTemp.template_id + "#" + "title";
          }
        } else {
          entity?.templates.forEach((temp) => {
            if (temp?.templateInfo && !temp?.is_repeatable) {
              if (!this.showFields.profile) {
                let field = (
                  this.getTemplateFields(temp.templateInfo) || []
                ).find((el) => el.inputType == "IMAGE");
                if (field?.key) {
                  this.showFields.profile = field.key;
                }
              }
              if (!this.showFields.description) {
                let field = (
                  this.getTemplateFields(temp.templateInfo) || []
                ).find((el) => el.inputType == "MULTI_LINE_TEXT");
                if (field?.key) {
                  this.showFields.description = field.key;
                }
              }
            }
          });
          if (
            !this.showFields.title &&
            entity.primaryFields &&
            entity.primaryFields[0]
          ) {
            this.showFields.title =
              entity.primaryFields[0].template_id +
              "#" +
              entity.primaryFields[0].key;
          }
        }
        entity?.templates.forEach((temp, i) => {
          if (temp?.templateInfo && i == 0) {
            let fields = (
              this.getTemplateFields(temp.templateInfo) || []
            ).filter((e) => this.contentFields.indexOf(e.input_type) === -1);
            if (fields.length) {
              fields.forEach((e) => {
                e.template_id = temp.template_id;
                if (
                  !this.showFields.fields ||
                  (this.showFields.fields.length < 7 &&
                    e.inputType != "DATA_TABLE")
                ) {
                  this.showFields.fields.push(e);
                }
              });
            }
          }
        });
      }
    },
    getTemplateFields(temaplate) {
      let fields = [];
      let data = JSON.parse(JSON.stringify(temaplate));
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        if (e.key && !e.key.includes("#")) {
          e.key = data._id + "#" + e.key;
        }
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      return fields;
    },
  },
  watch: {
    "$route.query": {
      handler: function () {
        if (this.$route.query.relationship_id) {
          this.currentRelationshipIndex = [
            ...this.getCurrentEntityRelationships,
          ].findIndex(
            (e) => e.relationship_id == this.$route.query.relationship_id
          );

          this.currentRelationshipDetails = [
            ...this.getCurrentEntityRelationships,
          ].find((e) => e.relationship_id == this.$route.query.relationship_id);

          this.currentEntityNestedRelationships = [
            ...this.getCurrentEntityNestedRelationships,
          ].find((e) => e._id == this.$route.query.relationship_id);
          if (
            this.currentRelationshipIndex > -1 &&
            this.currentRelationshipIndex + 1 <=
              this.getCurrentEntityRelationships.length - 1
          ) {
            this.hasNextRelationshipDetails =
              this.getCurrentEntityRelationships[
                this.currentRelationshipIndex + 1
              ];
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.error-container {
  text-align: center !important;
}
.filter-card {
  border: 2px solid #e6e6e6;
  border-radius: 6px;
  height: calc(100vh - 180px);
  width: 100% !important;
  text-align: center !important;
  align-content: center !important;
  align-items: center !important;
  .profile-empty {
    margin-top: 15px;
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: auto !important;
    margin-right: auto !important;
    background: #f5f5f5;
    font-size: 75px;
    color: #cdcdcd;
    i {
      margin-top: 25px !important;
    }
  }
  img {
    margin-top: 15px;
    width: 120px;
    height: 120px;
    object-fit: cover;
    // border-radius: 50%;
  }
  .card-body {
    padding: 5px;
    .card-title {
      color: #303133;
      font-weight: 500;
      font-size: 14px;
      // margin-bottom: 0px !important;
    }
    .card-descripion {
      font-weight: 400;
      font-size: 12px;
      color: #909399;
    }
  }
  .card-fields {
    padding: 8px;
    .card-fields-inner {
      border-top: #e6e6e6 solid 1px;
      text-align: left !important;
    }
  }
}
.field-label {
  color: #222222;
  font-size: 13px;
  font-weight: 400;
}
.field-value {
  font-size: 10px;
  color: #909399;
  font-weight: 400;
}
.data-card-container {
  height: calc(100vh - 180px);
  width: 100% !important;
}
.layout-selector {
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  color: #4c4b4e;
}
.default-view-container {
    margin:1em;
    padding:1em;
  .default-profile {
    display: flex;
    // display: grid;
    // grid-gap: 0.5em;
    // grid-template-columns: 300px auto;
    // @media (max-width: 700px) {
    //   grid-template-columns: repeat(1, 1fr);
    //   text-align: center !important;
    // }
  }
  .left-card {
    flex: 1;
    width: 100%;
  }
  .right-card {
    flex: 3;
    width: 100%;
  }
  .profile-holder {
    img {
      max-width: 300px;
      height: 240px;
      object-fit: cover;
    }
    .profile-empty-default {
      margin-top: 15px;
      max-width: 300px;
      height: 240px;
      object-fit: cover;
      margin-left: auto !important;
      margin-right: auto !important;
      background: #f5f5f5;
      font-size: 150px;
      color: #cdcdcd;
      text-align: center !important;
    }
  }
  .profile-details {
    width: 100%;
    .profile-heading {
      color: #303133;
      font-weight: 500;
      font-size: 18px;
    }
  }
  .default-card-fields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // height: 100px !important;
  }
}
.entity-navbar {
  display: flex;
  justify-content: space-between;
}
.left {
  order: 1;
}

.right {
  order: 2;
}
.profile-upper {
  position: relative;
}
#profile-d {
  position: absolute;
  @media (max-width: 1200px) {
    left: 40% !important;
  }
  @media (max-width: 500px) {
    left: 20% !important;
  }
  left: 39px;
  bottom: 0px;
  right: 0px;
  height: 180px;
  z-index: 2;
}

#profile-banner-image {
  height: 260px;
  overflow: hidden;
  z-index: 1;
}

#profile-banner-image img {
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  object-fit: contain !important;
}

#profile-pic {
  img {
    width: 180px !important;
    height: 180px !important;
    object-fit: cover;
    // border-radius: 50%;
  }
  margin-top: 80px;
}
.profile-empty-cover {
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 50%;
  background: #f5f5f5;
  font-size: 85px;
  color: #cdcdcd;
  text-align: center !important;
  justify-content: center !important;
  i {
    margin-top: 50px !important;
  }
}
.main-data-container {
  @media (max-width: 992px) {
    margin-top: 100px !important;
  }
  @media (max-width: 800px) {
    margin-top: 75px !important;
  }
  @media (max-width: 600px) {
    margin-top: 50px !important;
  }
  @media (max-width: 400px) {
    margin-top: 25px !important;
  }
}
.layout-container {
  padding-left: 20px;
  padding-right: 20px;
  .layout-head {
    color: #303133;
    font-size: 17px;
    font-style: normal;
    font-weight: 450;
    line-height: 140%;
    color: #303133;
    font-family: "Inter";
  }
  .sub-head {
    color: #303133;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: #303133;
    font-family: "Inter";
  }
}
.field-selection {
  color: #606266;
  padding: 5px;
  cursor: pointer;
}
.empty-profile-banner-image {
  width: 100% !important;
  height: 100% !important;
  background: #f5f5f5;
  font-size: 100px;
  color: #cdcdcd;
  text-align: center !important;
  justify-content: center !important;
  i {
    margin-top: 6% !important;
  }
}
</style>
<style scoped>
.custom-dialog-style .el-dialog__body {
  padding: 0; /* Remove padding on all sides */
}

.custom-footer-style {
  position: sticky;
  bottom: 0;
  background-color: #fff; /* Adjust background color as needed */
}
</style>
